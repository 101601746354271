<template>
  <span>

    <Menu></Menu>

    <Slider></Slider>

    <div class="body">

      <div class="container-fluid mt-1" style="background-color: #FFFFFF;">

          <div class="text-center pt-3 blue-txt bold-txt">DEPOSIT</div>
          <div class="text-center blue-txt">Please enter amount you want to deposit</div>
          <div class="form">

              <div class="form-group" style="margin-bottom: -1em;">

                  <div style="display: flex;justify-content: space-around;">

                    <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(33)">
                          <span class="bold">+</span> 33
                      </div>

                      <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(99)">
                          <span class="bold">+</span> 99
                      </div>

                      <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(298)">
                          <span class="bold">+</span> 298
                      </div>

                      <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(499)">
                          <span class="bold">+</span> 499
                      </div>

                      <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(1999)">
                          <span class="bold">+</span> 1999
                      </div>

                  </div>

                  <input type="number" v-model="amount" class="form-control small-txt" id="amount" aria-describedby="amountHelp" placeholder="KES 100">

              </div>

              <div  style="font-size: var(--font-small); text-align: left;">Minimum Kes 5</div>

              <button class="btn btn-sm bg-blue" @click="deposit" v-bind:class="loading">Deposit</button>

          </div>

          <h3 class="text-center">OR</h3>
          <h5 class="b-txt pb-1 pt-1">Follow the instructions below:</h5>
          <table class="table table-borderless">

              <tbody>
              <tr>
                  <th scope="row" class="b-txt pb-1 pt-1">1</th>
                  <td colspan="3" class="b-txt pb-1 pt-1">Go to M-PESA Menu on your mobile phone</td>
              </tr>
              <tr>
                  <th scope="row" class="b-txt pb-1 pt-1">2</th>
                  <td colspan="3" class="b-txt pb-1 pt-1">Select Lipa na M-PESA</td>
              </tr>
              <tr>
                  <th scope="row" class="b-txt pb-1 pt-1">3</th>
                  <td colspan="3" class="b-txt pb-1 pt-1">Select Pay Bill</td>
              </tr>
              <tr>
                  <th scope="row" class="b-txt pb-1 pt-1">4</th>
                  <td colspan="3" class="b-txt pb-1 pt-1">Enter 792929 as the Business Number</td>
              </tr>
              <tr>
                  <th scope="row" class="b-txt pb-1 pt-1">5</th>
                  <td colspan="3" class="b-txt pb-1 pt-1">Enter BET as Account Number</td>
              </tr>
              <tr>
                  <th scope="row" class="b-txt pb-1 pt-1">6</th>
                  <td colspan="3" class="b-txt pb-1 pt-1">Enter the amount to deposit. For example 200</td>
              </tr>
              <tr>
                  <th scope="row" class="b-txt pb-1 pt-1">7</th>
                  <td colspan="3" class="b-txt pb-1 pt-1">Enter your M-PESA PIN and send</td>
              </tr>
              <tr>
                  <th scope="row" class="b-txt pb-1 pt-1">8</th>
                  <td colspan="3" class="b-txt pb-1 pt-1">You will receive an SMS from M-pesa and 40600 confirming the transaction</td>
              </tr>
              </tbody>
          </table>

      </div>

      <BottomFooter></BottomFooter>

      <bottom-navigation active-bets="0" page="deposit"></bottom-navigation>

    </div>

  </span>
</template>

<script>

import BottomFooter from './BottomFooter'
import Menu from './Menu'
import Slider from './Slider'
import BottomNavigation from './BottomNavigation'

import axios from "@/services/api";

export default {
  name: 'Deposit',
  components: {
    Menu,
    BottomFooter,
    BottomNavigation,
    Slider
  },
  data: function () {
    return {
      amount: 0,
      loading: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","deposit");
    this.reloadProfile();
  },
  methods: {
    setAmount: function (amount) {

      this.amount = amount
      this.deposit();

    },
    deposit: function () {

      this.reset();
      var p = this.getProfile();

      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      if(this.amount < 1 ) {

        this.setError("Invalid Amount","ENter amount atleast 1 KSH or above");
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.message;
            vm.setSuccess("Deposit Initiated",msg);

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;
              } else {

                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));
              }

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })
    }

  }
}
</script>